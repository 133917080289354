export const auth = {
  landing: '/auth',
  forgotPassword: '/auth/forgot-password',
  login: '/auth/login',
  logout: '/auth/logout',
  resetPassword: '/auth/reset-password',
  ssoBouncer: '/auth/sso-callback',
};

export const redirect = {
  habuIntelligence: '/habuintelligence',
  helpDocs: '/help-docs',
  habuHelp: '/habu-help',
  helpDocumentation: '/help-documentation',
  supportPortal: '/support-portal',
  deeplinkRedirect: '/redirect',
  linkedinAuth: '/linkedin-auth',
};

export const activationChannels = {
  landing: () => '/activation-channels',
  detail: (i = ':channelName') => `/activation-channels/details/${i}`,
  createActivationChannel: () => '/activation-channels/create',
  createCustomActivationChannel: '/activation-channels/create-custom-channel',
  partnerAccountDetail: (i = ':channelName', j = ':partnerAccountID') => `/activation-channels/details/${i}/account-details/${j}`,
};

export const apiKeyManagement = {
  landing: '/api-key',
};

export const automatedSegmentation = {
  details: (i = ':jobId') => `/automated-segmentation/details/${i}`,
};

export const cleanRoom = {
  activationChannels: (i = ':crId') => `/clean-rooms/details/${i}/activation-channels`,
  alertNotifications: (i = ':crId') => `/clean-rooms/details/${i}/alerts`,
  alertResults: (i = ':crId', j = ':alertId', k = ':timestamp') => `/clean-rooms/details/${i}/alerts/${j}/${k}`,
  assignDatasetsWizard: (i = ':crId', j = ':questionId') => `/clean-rooms/${i}/question/${j}/assign-datasets`,
  manageDatasets: (i = ':crId') => `/clean-rooms/details/${i}/datasets`,
  datasetWizard: (i = ':jobDs', j = ':crId', k = ':dataImportJobId', l = ':datasetId?') => `/clean-rooms/${i}/details/${j}/import-job/${k}/dataset/${l}`,
  details: (i = ':crId') => `/clean-rooms/details/${i}`,
  flowLanding: (i = ':crId') => `/clean-rooms/details/${i}/flow`,
  flowRunList: (i = ':crId', j = ':crFlowId?') => `/clean-rooms/details/${i}/flow/${j}/run/list`,
  flowBuilder: (i = ':crId', j = ':crFlowId?', k = ':flowId') => `/clean-rooms/details/${i}/flow/builder/${j}/${k}`,
  flowRunReport: (i = ':crId', j = ':crFlowId?', k = ':runId') => `/clean-rooms/details/${i}/flow/${j}/run/report/${k}`,
  flowView: (i = ':crId', j = ':crFlowId?', k = ':flowId') => `/clean-rooms/details/${i}/flow/view/${j}/${k}`,
  flowManageDatasets: (i = ':crId', j = ':crFlowId?') => `/clean-rooms/details/${i}/flow/${j}/manage-datasets`,
  question: (i = ':crId') => `/clean-rooms/details/${i}/question`,
  landing: '/clean-rooms',
  landingTemplate: '/clean-rooms/template',
  partnerManagement: (i = ':crId') => `/clean-rooms/details/${i}/management/partner`,
  userManagement: (i = ':crId') => `/clean-rooms/details/${i}/management/user`,
  partnersAndUsers: (i = ':crId') => `/clean-rooms/details/${i}/access/partners-and-users`,
  manageRoles: (i = ':crId', p = ':partnerId') => `/clean-rooms/details/${i}/access/partners-and-users/${p}/roles`,
  partnerInvitations: (i = ':crId') => `/clean-rooms/details/${i}/access/partners-and-users/partner-invitations`,
  runList: (i = ':crId', j = ':questionId', k = ':crQId') => `/clean-rooms/details/${i}/question/${j}/cr-question/${k}/run/list`,
  scheduledRunsList: (i = ':crId', j = ':questionId', k = ':crQId') => `/clean-rooms/details/${i}/question/${j}/cr-question/${k}/scheduled-runs`,
  runReport: (i = ':crId', j = ':questionId', k = ':crQId', l = ':runId', v = ':version') => `/clean-rooms/details/${i}/question/${j}/cr-question/${k}/run/report/${l}/${v}`,
  wizard: (i = ':crId?') => `/clean-rooms/wizard/${i}`,
  templateWizard: (i = ':crId?') => `/clean-rooms/template-wizard/${i}`,
  inboundInvitations: '/clean-rooms/clean-room-inbound-invitations',
  awsSettings: (i = ':crId') => `/clean-rooms/details/${i}/clean-room-aws-settings`,
  cleanRoomSettings: (i = ':crId') => `/clean-rooms/details/${i}/clean-room-settings`,
  cleanRoomPermissions: (i = ':crId') => `/clean-rooms/details/${i}/clean-room-settings/permissions`,
  questionActivation: {
    list: (i = ':crId', j = ':questionId', k = ':crQuestionId') => `/clean-rooms/details/${i}/question/${j}/cr-question/${k}/activation`,
  },
  exports: (i = ':crId') => `/clean-rooms/details/${i}/exports`,
  userDefinedFunctions: (i = ':crId') => `/clean-rooms/details/${i}/user-defined-functions`,
  runSummary: (i = ':crId') => `/clean-rooms/details/${i}/run-summary`,
};

export const crPartners = {
  create: (i = ':crId') => `/clean-rooms/${i}/partner/add`,
  list: (i = ':crId') => `/clean-rooms/details/${i}?selectedTab=partners`,
};

export const crUserList = {
  create: (i = ':crId', j = ':id?') => `/clean-rooms/user-lists/create/${i}/${j}`,
  details: (i = ':crId', j = ':id') => `/clean-rooms/${i}/user-lists/${j}`,
  list: (i = ':crId') => `/clean-rooms/details/${i}/user-lists`,
};

export const dataIn = {
  dataInJobWizard: (j = ':jobId?') => `/ingestion/jobs/${j}`,
  dataInJobReports: (i = ':dataSourceId', j = ':importJobId') => `/ingestion/${i}/report/${j}`,
  dataInFieldMapping: (d = ':dataSourceId', j = ':jobId') => `/ingestion/${d}/jobs/${j}/fieldMapping`,
  dataInJobStats: (dataSourceId, jobId) => `/ingestion/${dataSourceId}/report/${jobId}`,
  jobDetails: (j = ':jobId') => `/ingestion/job/${j}`,
  landing: '/ingestion',
  userDefinedFunctions: '/user-defined-functions',
};

export const dataOut = {
  activationReport: (i = ':jobId') => `/activation/${i}/report`,
  createActivation: (source = ':source?') => `/activation/${source}/create`,
  createExport: '/export/create',
  landing: (t = ':tab?') => `/data-out/${t}`,
  userListsActivation: '/activations-user-list',
  offlineConversions: '/activations-offline-conversion',
  dataExports: '/activations-export',
};

export const experiments = {
  landing: '/experiments',
  details: (r = ':experimentId') => `/experiments/report/${r}`,
  wizard: (id = ':id?') => `/experiments/wizard/${id}`,
};

export const errorPages = {
  forbidden: '/403',
  pageNotFound: '/404',
  resourceNotFound: '/423',
};

export const fullViewAutomation = {
  wizard: (id = ':id?') => `/full-view-automation/wizard/${id}`,
  landing: '/full-view-automation/partners',
  questionList: (f = ':fvaId') => `/full-view-automation/${f}`,
  report: (f = ':fvaId', q = ':questionId', r = ':runId?') => `/full-view-automation/${f}/${q}/${r}`,
};

export const helium = {
  primaryLanding: '/helium',
  cleanRoom: {
    landing: '/helium/clean-rooms',
    details: (id = ':crId') => `/helium/clean-rooms/details/${id}`,
    create: '/helium/clean-rooms/create',
    settings: (id = ':crId') => `/helium/clean-rooms/${id}/settings`,
    joinedDataSet: {
      landing: '/helium/clean-rooms/joined-datasets',
      details: (joinedDatasetID = ':joinedDatasetID') => `/helium/clean-rooms/joined-datasets/view/${joinedDatasetID}`,
      automatedJoins: {
        create: '/helium/clean-rooms/joined-datasets/automated-joins/create',
        edit: (id = ':id') => `/helium/clean-rooms/joined-datasets/automated-joins/edit/${id}`,
      },
      customJoins: {
        create: '/helium/clean-rooms/joined-datasets/custom-joins/create',
        edit: (id = ':id') => `/helium/clean-rooms/joined-datasets/custom-joins/edit/${id}`,
      },
    },
    datasetWizard: (id = ':crId') => `/helium/clean-rooms/${id}/manage-datasets/wizard`,
    mockCleanRoom: '/helium/clean-rooms/1',
  },
  dataConnections: {
    landing: '/helium/data-connections',
    defaultGovernance: '/helium/data-connections/default-governance',
    wizard: (j = ':jobId') => `/helium/data-connections/jobs/${j}`,
  },
  manageDatasets: {
    landing: (id = ':crId') => `/helium/clean-rooms/${id}/manage-datasets`,
  },
  userManagement: {
    landing: (id = ':crId') => `/helium/clean-rooms/${id}/user-management`,
  },
  partners: {
    landing: (id = ':crId') => `/helium/clean-rooms/${id}/partners`,
  },
  userLists: {
    landing: (id = ':crId') => `/helium/clean-rooms/${id}/user-lists`,
  },
};

export const homepage = '/welcome';

export const iam = {
  accountAdmin: '/iam/manage-account-admin',
  accountAdminDetails: (i = ':id?') => `/iam/account-admin-details/${i}`,
  accountAdminInvitations: '/iam/account-admin-invitations',
  accountList: '/iam/account-list',
  createAccount: '/iam/account',
  newOrganization: '/iam/account/organization',
  organizationDetails: '/iam/account/organization/details',
  organizationList: '/iam/account/organization-list',
  roleDetails: (i = ':id?') => `/iam/roles/${i}`,
  userDetails: (i = ':id?') => `/iam/users/${i}`,
};

export const internalAdmin = {
  landing: '/internal-admin',
  account: {
    landing: '/internal-admin/account/',
    accountProductAccess: '/internal-admin/account/product-access',
    cleanRoomTypes: '/internal-admin/account/clean-room-types',
  },
  application: {
    landing: '/internal-admin/application/',
    cleanRoomQuestions: '/internal-admin/application/clean-room-questions',
    cleanRoomQuestion: (questionId = ':questionId?') => `/internal-admin/application/clean-room-question/${questionId}`,
    credentialSources: '/internal-admin/application/credential-sources',
    credentialSourceDetail: '/internal-admin/application/data-sources/credential-sources',
    permissionWizard: (i = ':permissionId?') => `/internal-admin/application/permission/${i}`,
    productPermissions: '/internal-admin/application/product-permissions',
    productPermissionDetails: (i = ':prodID?') => `/internal-admin/application/product-permissions/product/${i}/permission`,
    snowflakeCliDashboard: '/internal-admin/application/snowflake-cli-dashboard',
  },
  organization: {
    landing: '/internal-admin/organization/',
    alerts: (crId = ':crId?') => `/internal-admin/organization/alerts/${crId}`,
    alertBuilder: (crId = ':crId?') => `/internal-admin/organization/alerts/${crId}/alert-builder`,
    alertBuilderEdit: (crId = ':crId?', alertId = ':alertId?') => `/internal-admin/organization/alerts/${crId}/alert-builder/${alertId}`,
    alertsRequest: (crId = ':crId?') => `/internal-admin/organization/alerts-request/${crId}`,
    demoData: '/internal-admin/organization/demo-data',
    importDataSourceAccess: '/internal-admin/organization/import-data-source-access',
    importDataTypeAccess: '/internal-admin/organization/import-data-type-access',
    syntheticData: '/internal-admin/organization/synthetic-data',
    organizationProductAccess: '/internal-admin/organization/product-access',
    organizationProperties: '/internal-admin/organization/properties',
    organizationParameters: '/internal-admin/organization/parameters',
    organizationPropertyDetails: (propertyId = ':propertyId?') => `/internal-admin/organization/property/${propertyId}`,
    organizationPropertyConfigurationDetails: (propertyId = ':propertyId', configurationId = ':configurationId?') => `/internal-admin/organization/property/${propertyId}/configuration/${configurationId}`,
    cleanRoomTypes: '/internal-admin/organization/clean-room-types',
  },
};

export const metabase = {
  habuintelligenceEmbed: '/habuintelligenceEmbed',
  crHabuIntelligence: (i = ':crId') => `/clean-rooms/details/${i}/habuintelligenceEmbed`,
};

export const question = {
  builder: {
    landing: '/question/builder',
    wizard: {
      landing: '/question/builder/wizard/',
      cleanRoom:
        (i = ':questionId?', j = ':crId?', k = ':crqId?') => (k ? `/question/builder/wizard/clean-room/details/${j}/${i}/${k}`
          : `/question/builder/wizard/clean-room/details/${j}/${i}`),
      flow: (i = ':questionId?', j = ':crId?') => `/question/builder/wizard/flow/details/${j}/${i}`,
      organization: (i = ':questionId?') => `/question/builder/wizard/organization/${i}`,
      account: (i = ':questionId?') => `/question/builder/wizard/account/${i}`,
      platform: (i = ':questionId?') => `/question/builder/wizard/platform/${i}`,
    },
    clone: {
      landing: '/question/builder/clone/',
      cleanRoom: (i = ':questionId?', j = ':crId?') => `/question/builder/clone/clean-room/details/${j}/${i}`,
      flow: (i = ':questionId?', j = ':crId?') => `/question/builder/clone/flow/details/${j}/${i}`,
      organization: (i = ':questionId?') => `/question/builder/clone/organization/${i}`,
      account: (i = ':questionId?') => `/question/builder/clone/account/${i}`,
      platform: (i = ':questionId?') => `/question/builder/clone/platform/${i}`,
    },
  },
};

export const organizationManagement = {
  landing: '/organization-management',
  organization: {
    landing: '/organization-management/organization',
    gpgKeyManagement: '/organization-management/organization/gpg-key',
    credentials: {
      list: '/organization-management/organization/credentials',
      wizard: (credId = ':credId') => `/organization-management/organization/credentials/wizard/${credId}`,
    },
    dataSourceLocations: '/organization-management/organization/data-source-locations',
    properties: {
      list: '/organization-management/organization/properties/list',
      create: '/organization-management/organization/properties/create',
      edit: (id = ':id') => `/organization-management/organization/properties/edit/${id}`,
    },
    roles: {
      list: '/organization-management/organization/roles/list',
      create: '/organization-management/organization/roles/wizard/create',
      edit: (roleId = ':roleId') => `/organization-management/organization/roles/${roleId}`,
    },
    users: {
      list: '/organization-management/organization/users/list',
      create: '/organization-management/organization/users/create',
      edit: (id = ':id') => `/organization-management/organization/users/edit/${id}`,
    },
    customization: {
      landing: '/organization-management/organization/customization',
      edit: '/organization-management/organization/customization/edit',
    },
    orgDataCredential: '/internal-admin/organization/credential',
    identity: {
      list: '/organization-management/identity',
      add: '/organization-management/identity/add',
      edit: (id = ':id') => `/organization-management/identity/edit/${id}`,
    },
  },
  cleanRoom: {
    OrgApiLinking: '/organization-management/solutions/invitations',
  },
};

export const questionManagement = {
  landing: '/question-management',
  orgQuestions: (t = ':tab?') => `/question-management/organization/${t}`,
  provisionWizardV2: (q = ':qIds') => `/question-management/question-provision/v2/${q}`,
  provisionWizardV2Platform: (q = ':qIds') => `/question-management/question-provision/v2/platform/${q}`,
  provisionQuestionWizard: (q = ':qIds') => `/question-management/question-provision/${q}`,
  platformQuestionSettings: (i = ':qId') => `/question-management/platform/${i}/settings`,
  accQuestionSettings: (i = ':qId') => `/question-management/account/${i}/settings`,
  crQuestionSettings: (i = ':qId') => `/question-management/clean-room/question/${i}/settings`,
};

export const userList = {
  detail: (userListId = ':userListId') => `/user-lists/${userListId}`,
  list: '/user-lists',
  wizard: (i = ':userListId?') => `/user-lists/wizard/${i}`,
};

export const flow = {
  landing: '/flow',
  builder: (i = ':flowId?') => `/flow/builder/${i}`,
  questionBuilder: (i = ':questionId?', j = ':crId?') => `/question/builder/clone/clean-room/details/${j}/${i}`,
};

export const supportMode = {
  enable: '/supportMode/enable',
};

export const trustCenter = {
  landing: '/trust-center',
  datasets: '/trust-center/datasets',
};
