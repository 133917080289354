import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FlowRunForm from 'pages/Flow/FlowRun/FlowRunForm';
import FlowRunMacroTable from 'pages/Flow/FlowRun/FlowRunMacroTable';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import PermissionService from 'components/Common/PermissionService';
import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FlowContext } from 'pages/Flow/FlowContextProvider';
import { FlowRunContext } from 'pages/Flow/FlowRun/FlowRunProvider';
import {
  HButton,
  HDialogActions,
  HDialogTitle,
  HSpinner,
} from 'BaseComponents';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { productPerm } from 'utils/appConstants';
import { useFetchFlowRunParameters, useSubmitFlowRun } from 'hooks/Flow/flowAPI';
import {
  useGetCRFlowRunParametersAndMappings,
} from 'api/flows/useGetCRFlowRunParametersAndMappings';
import { useGetDPAccountantView } from 'api/cleanrooms/useGetDPAccountantView';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


const FlowRunDialog = () => {
  const { crId } = useParams();
  const {
    dataContextRef,
  } = useContext(FlowContext);
  const { isOpen, closeModal, modalDetails } = React.useContext(FlowRunContext);
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [runDetails, setRunDetails] = React.useState({ name: '', flowParameters: [] });
  const [transformedData, setTransformedData] = React.useState([]);
  const [macroData, setMacroData] = React.useState([]);
  const hasFLowMacroPermission = PermissionService
    .isProductEnabled([productPerm.FlowMacros]);
  const [flowRunCreationStep, setFlowRunCreationStep] = React.useState(
    hasFLowMacroPermission ? 0 : 1);

  const { ID: orgID } = useSelector(activeOrgSelector);
  const crDetail = useSelector(state => state.cleanRooms.detail);

  const isDifferentialPrivacyEnabled = PermissionService
    .isProductEnabled([productPerm.DifferentialPrivacy]);

  const handleModalClose = () => {
    closeModal();
    setRunDetails({ name: '', flowParameters: [] });
    setFlowRunCreationStep(hasFLowMacroPermission ? 0 : 1);
  };

  const handleNext = () => setFlowRunCreationStep(1);
  const handleBack = () => {
    if (hasFLowMacroPermission) {
      setFlowRunCreationStep(0);
    }
    else {
      handleModalClose();
    }
  };

  const {
    isLoading: runParamAPILoading,
    isSuccess: runParamAPISuccess,
    data,
  } = useFetchFlowRunParameters(
    { crFlowId: modalDetails?.ID, crId },
  );

  const {
    isError: isFlowRunParamMappingError,
    isSuccess: isFlowRunParamMappingSuccess,
    isLoading: isFlowRunParamMappingLoading,
    data: flowRunParametersMapping,
  } = useGetCRFlowRunParametersAndMappings({ crFlowId: modalDetails?.ID, crId });

  const {
    refetch: refetchBudgetAccountant,
  } = useGetDPAccountantView(orgID, crId, false);

  const {
    mutate,
    isSuccess: flowRunAPISuccess,
    reset: resetFlowRunMutation,
    isloading: loading,
  } = useSubmitFlowRun({ flowId: modalDetails?.ID, crId });

  const processFlowRunParameters = (flowRunParameters) => {
    const macros = [];
    const runParametersWithoutMacros = [];
    const macroIds = new Set();

    flowRunParameters.forEach(flowRunParam => {
      const nodeRunParamsWithoutMacros = {
        nodeID: flowRunParam.nodeID,
        nodeQuestionName: flowRunParam.nodeQuestionName,
        nodeRunParameters: [],
      };

      flowRunParam.nodeRunParameters.forEach(nodeRunParam => {
        // Create a new object to avoid reassigning the parameter
        const newNodeRunParam = { ...nodeRunParam, value: '' };

        // Special check for START_DATE and END_DATE
        if (!newNodeRunParam.ID) {
          if (newNodeRunParam.name === 'START_DATE') {
            newNodeRunParam.ID = 'START_DATE';
          }
          else if (newNodeRunParam.name === 'END_DATE') {
            newNodeRunParam.ID = 'END_DATE';
          }
        }

        if (newNodeRunParam.macro) {
          if (!macroIds.has(newNodeRunParam.macro.ID)) {
            const macroWithMapping = {
              ...newNodeRunParam.macro,
              runParamMapping: [],
            };
            macros.push(macroWithMapping);
            macroIds.add(newNodeRunParam.macro.ID);
          }
          const macro = macros.find(m => m.ID === newNodeRunParam.macro.ID);
          macro.runParamMapping.push({
            nodeID: flowRunParam.nodeID,
            runParamID: newNodeRunParam.ID,
            runParamName: newNodeRunParam.name,
          });
        }
        else {
          nodeRunParamsWithoutMacros.nodeRunParameters.push(newNodeRunParam);
        }
      });

      // Avoid Empty Entries: Only add nodeRunParameters if it has at least one entry
      if (nodeRunParamsWithoutMacros.nodeRunParameters.length > 0) {
        runParametersWithoutMacros.push(nodeRunParamsWithoutMacros);
      }
    });

    return { macros, runParametersWithoutMacros };
  };


  React.useEffect(() => {
    if (isFlowRunParamMappingSuccess && flowRunParametersMapping) {
      const res = processFlowRunParameters(flowRunParametersMapping?.data?.flowRunParameters);
      setMacroData(res.macros);
      setTransformedData(res.runParametersWithoutMacros);
    }
  }, [flowRunParametersMapping, isFlowRunParamMappingSuccess]);

  const transformData = (d) => d.map(item => ({
    ...item,
    nodeRunParameters: item.nodeRunParameters.map(param => ({
      ...param,
      value: '', // Add the "Value" key with an empty string value
    })),
  }));

  // Use useEffect to update transformedData when runParamAPISuccess or data changes
  React.useEffect(() => {
    if (runParamAPISuccess && !hasFLowMacroPermission) {
      const newTransformedData = transformData(data?.data.flowRunParameters);
      setTransformedData(newTransformedData);
    }
  }, [runParamAPISuccess, data, hasFLowMacroPermission]);

  const areAllMacrosValid = (m) => m
    .every(macro => macro.value && macro.value.trim() !== '');

  const isAllTransformedDataValid = (d) => d
    .every(item => item.nodeRunParameters.every(param => param.value && param.value.trim() !== ''));

  React.useEffect(() => {
    let isValid = false;
    if (flowRunCreationStep === 0 && hasFLowMacroPermission) {
      isValid = areAllMacrosValid(macroData);
    }
    else if (flowRunCreationStep === 1) {
      isValid = isAllTransformedDataValid(transformedData);
    }
    setIsFormValid(isValid);
  }, [macroData, transformedData, flowRunCreationStep, hasFLowMacroPermission]);

  if (modalDetails === null) return null;


  const handleSubmit = () => {
    // Transform transformedData into a map for quick lookup by nodeID
    const transformedDataMap = new Map(
      transformedData.map(item => [
        item.nodeID,
        Object.fromEntries(item.nodeRunParameters.map(param => [param.name, param.value])),
      ]),
    );

    // Transform macroData into a map for quick lookup by nodeID
    const macroDataMap = new Map();
    macroData.forEach(macro => {
      macro.runParamMapping.forEach(mapping => {
        if (!macroDataMap.has(mapping.nodeID)) {
          macroDataMap.set(mapping.nodeID, {});
        }
        macroDataMap.get(mapping.nodeID)[mapping.runParamName] = macro.value;
      });
    });

    // Merge the data from both maps
    const mergedData = Array.from(new Set([...transformedDataMap.keys(), ...macroDataMap.keys()]))
      .map(nodeID => ({
        nodeID,
        parameters: { ...transformedDataMap.get(nodeID), ...macroDataMap.get(nodeID) },
      }));

    const payload = { cleanRoomFlowRun: { ...runDetails, flowParameters: mergedData } };
    mutate(payload);
  };

  const handleNodeRunParamFormFieldChange = ({ e, nodeID, nodeParam }) => {
    const { value } = e.target;
    const { ID: selectedParamID, name: selectedParamName } = nodeParam;
    const updatedData = transformedData.map(item => {
      if (item.nodeID === nodeID) {
        const updatedNodeRunParameters = item.nodeRunParameters.map(param => {
          const enabledStartDate = selectedParamName === 'START_DATE' && param.name === 'START_DATE';
          const enabledEndDate = selectedParamName === 'END_DATE' && param.name === 'END_DATE';
          const enabledDateParameters = selectedParamName === 'START_DATE' || selectedParamName === 'END_DATE';

          if (param.ID === selectedParamID) {
            if (enabledStartDate && enabledDateParameters) {
              return { ...param, value };
            }

            if (enabledEndDate && enabledDateParameters) {
              return { ...param, value };
            }

            if (!enabledEndDate && !enabledStartDate && !enabledDateParameters) {
              return {
                ...param,
                value,
              };
            }
          }

          return param;
        });

        return {
          ...item,
          nodeRunParameters: updatedNodeRunParameters,
        };
      }
      return item;
    });

    setTransformedData(updatedData);
  };
  const updateMacroValue = (macros, macroID, newValue) => macros.map(macro => {
    if (macro.ID === macroID) {
      return { ...macro, value: newValue };
    }
    return macro;
  });

  const handleUpdateMacroValue = (macroID, newValue) => {
    setMacroData(prevMacros => updateMacroValue(prevMacros, macroID, newValue));
  };

  if (flowRunAPISuccess) {
    resetFlowRunMutation();
    setRunDetails({ name: '', flowParameters: [] });
    dataContextRef.current.refreshListData();
    if (isDifferentialPrivacyEnabled && crDetail?.cleanRoomPrivate) {
      refetchBudgetAccountant();
    }
    closeModal();
  }


  const renderStep = () => {
    switch (flowRunCreationStep) {
      case 0:
        return (
          <FlowRunMacroTable
            error={isFlowRunParamMappingError}
            loading={isFlowRunParamMappingLoading}
            listOfFlowMacros={macroData}
            onUpdateMacroValue={handleUpdateMacroValue}
          />
        );
      case 1:
        return (
          <FlowRunForm
            flowRunTimeParameters={transformedData}
            hasFLowMacroPermission={hasFLowMacroPermission}
            loading={runParamAPILoading}
            runDetails={runDetails}
            updateNodeRunParam={handleNodeRunParamFormFieldChange}
            updateRunDetails={setRunDetails}
          />
        );
      default:
        return null;
    }
  };


  const runDialogActions = {
    0: {
      onClose: handleModalClose,
      onCloseText: 'Cancel',
      onSubmit: handleNext,
      onSubmitText: 'Next',
      disabled: !isFormValid || isFlowRunParamMappingError,
    },
    1: {
      onClose: handleBack,
      onCloseText: hasFLowMacroPermission ? 'Back' : 'Cancel',
      onSubmit: handleSubmit,
      onSubmitText: 'Create Run',
      disabled: !isFormValid,
    },
  };

  const {
    onClose, onCloseText, onSubmit, onSubmitText, disabled,
  } = runDialogActions[flowRunCreationStep] || {};

  return (
    <Dialog
      onClose={handleModalClose}
      open={isOpen}
      fullWidth
      maxWidth='md'
    >
      <HDialogTitle onClose={handleModalClose}>
        <Typography variant='h3'>New Flow Run</Typography>
      </HDialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                data-testid='report-flow-text-field'
                fullWidth
                label='Run Name'
                margin='normal'
                onChange={e => setRunDetails({
                  ...runDetails,
                  name: e.target.value,
                })}
                required
                type='text'
                value={runDetails.name || ''}
                variant='outlined'
              />
            </Grid>
            {renderStep()}
          </Grid>
        </FormControl>
      </DialogContent>
      <HDialogActions>
        <HButton variant='outlined' onClick={onClose}>{onCloseText}</HButton>
        <HButton
          variant='contained'
          onClick={onSubmit}
          disabled={loading || disabled}
          endIcon={loading ? <HSpinner isButton /> : null}
        >
          {onSubmitText}
        </HButton>
      </HDialogActions>
    </Dialog>
  );
};

export default FlowRunDialog;
