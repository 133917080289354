
// Preset of Organization Parameters
const PRESET_ORGANIZATION_PARAMETERS = {
  SUPPORTED_CLOUD: 'SUPPORTED_CLOUD',
  CONNECTOR: 'CONNECTOR',
};

export const PRESET_ORGANIZATION_PARAMETERS_NAMES = Object.values(PRESET_ORGANIZATION_PARAMETERS);

// Preset of Organization Values by Organization Parameter Name
export const PRESET_ORGANIZATION_VALUES = {
  [PRESET_ORGANIZATION_PARAMETERS.SUPPORTED_CLOUD]: {
    options: ['AWS', 'GCP', 'AZURE'],
    autocompleteProps: {
      multiple: true,
    },
  },
  [PRESET_ORGANIZATION_PARAMETERS.CONNECTOR]: {
    options: ['LINKEDIN', 'MICROSOFT', 'META'],
  },
};

// Preset of Organization Categories by Organization Parameter Name
export const PRESET_ORGANIZATION_CATEGORIES = {
  [PRESET_ORGANIZATION_PARAMETERS.SUPPORTED_CLOUD]: {
    options: ['TEE'],
  },
  [PRESET_ORGANIZATION_PARAMETERS.CONNECTOR]: {
    options: ['TEE'],
  },
};

export const isInputOrgParameter = (item) => item.type === 'add';
