import { DATA_SOURCE_NAME, Usage } from 'components/DataIngestion/constants';
import { DISPLAY_NAME_REBRAND, ICEBERG_CATALOG_DATA_CONNECTION } from 'components/DataConnection/constants';
import { v4 as uuidGen } from 'uuid';


export const setParameterIDs = (parameters) => parameters.map((parameter) => {
  if (parameter.hasDependencies) {
    return {
      ...parameter,
      ID: uuidGen(),
      dependantParameters: setParameterIDs(parameter.dependantParameters),
    };
  }
  return { ...parameter, ID: uuidGen() };
});

export const splitMultipleParameters = (parameters) => {
  const result = [];

  parameters.forEach(parameter => {
    const newParam = { ...parameter };
    if (parameter.hasDependencies && parameter.dependantParameters?.length) {
      newParam.dependantParameters = splitMultipleParameters(parameter.dependantParameters);
    }
    if (Array.isArray(newParam.value)) {
      if (newParam.value.length) {
        newParam.value.forEach(val => {
          result.push({ ...newParam, value: String(val) });
        });
      }
      else {
        result.push({ ...newParam, value: '' });
      }
    }
    else {
      result.push(newParam);
    }
  });
  return result;
};

export const getMultipleParametersMap = (parameters) => {
  if (!parameters || !parameters.length) return {};
  const groupedMultipleParameters = {};
  parameters.forEach((parameter) => {
    if (parameter.usage === Usage.MULTIPLE) {
      groupedMultipleParameters[parameter.name] = {
        parameter,
        count: (groupedMultipleParameters[parameter.name]?.count || 0) + 1,
      };
    }
  });
  return groupedMultipleParameters;
};

export const showDeleteButton = (parameter, multipleParametersMap) => {
  if (parameter.usage === Usage.MULTIPLE) {
    return multipleParametersMap[parameter.name]?.count > 1;
  }
  return false;
};

export const showAddButton = (parameter, multipleParametersMap) => {
  if (parameter.usage === Usage.MULTIPLE) {
    return multipleParametersMap[parameter.name]?.parameter?.ID === parameter.ID;
  }
  return false;
};

export const getDataLocation = (job, parameters, orgId) => {
  /*
   * Handle the special case for data location
   * DataLocation is computed on the client side
   * It is dynamically generated using job name, refresh interval and frequency
   * So it will have to change when either changes.
   * Solution: update the DataLocation parameter whenever either dependencies get updated
   */

  const isLocalUpload = job.importDataSource.name === DATA_SOURCE_NAME.HABU_LOCAL;
  const isCsvCatalog = job.importDataSource.name.includes(DATA_SOURCE_NAME.CSV_CATALOG);

  const frequency = parameters?.find(i => i.name === 'JobFrequency') ?? {};
  const refreshType = parameters?.find(i => i.name === 'RefreshType') ?? {};
  const dataLocationParameter = parameters?.find(i => i.name === 'DataLocation') ?? { ID: uuidGen() };

  const host = job?.importDataSource.name === DATA_SOURCE_NAME.HABU_GCS ? 'gs' : 's3';
  let location;

  if (!job?.name) { location = 'Please Enter A Job Name'; }
  else if (isLocalUpload) {
    const sanitizedJobName = job?.name.trim().replace(/\s/g, '-');
    location = `${host}://habu-client-org-${orgId}/uploads/${sanitizedJobName}/local`;
  }
  else if (isCsvCatalog) {
    const sanitizedJobName = job?.name.trim().replace(/\s/g, '-');
    location = `${host}://habu-client-org-${orgId}/uploads/${sanitizedJobName}/${String(job?.importDataSource.name).toLowerCase()}`;
  }
  else {
    const sanitizedJobName = job?.name.trim().replace(/\s/g, '-');
    const frequencyPart = frequency.value ? `/${frequency.value}` : ''; // Use frequency if available
    const refreshTypePart = refreshType.value ? `/${refreshType.value}` : ''; // Use refresh type if available
    location = `${host}://habu-client-org-${orgId}/uploads/${job.importDataSource.name}/${sanitizedJobName}${frequencyPart}/{yyyy-MM-dd}${refreshTypePart}`;
  }
  return {
    ...dataLocationParameter,
    organizationID: orgId,
    value: location,
  };
};

export const flattenParameters = (parameters, orgId) => parameters?.reduce((acc, param) => {
  acc.push({ ...param, organizationID: orgId });
  if (param.dependantParameters && param.dependantParameters.length > 0) {
    param.dependantParameters.forEach(dp => {
      if (dp.parentValues.indexOf(param.value) >= 0) {
        const value = Array.isArray(dp.value) ? dp.value.join(',') : dp.value;
        acc.push({ ...dp, organizationID: orgId, value });
      }
    });
  }
  return acc;
}, []);

export const isJobChanged = (oldJob, newJob) => {
  if (!oldJob) return true;
  return JSON.stringify(oldJob) !== JSON.stringify(newJob);
};

export const areParametersChanged = (oldParameters, newParameters) => {
  if (!oldParameters) return true;
  return JSON.stringify(oldParameters) !== JSON.stringify(newParameters);
};

// Update the data source display name on the fly to assign with the rebranding change
export const updateDisplayName = (displayName) => DISPLAY_NAME_REBRAND[displayName] ?? displayName;

export const isIcerbergCatalog = (dataSourceName) => (
  dataSourceName === ICEBERG_CATALOG_DATA_CONNECTION
);

const getExcludeLookupValues = (selectedCredential) => {
  switch (selectedCredential?.credentialSource.name) {
    case 'Polaris Catalog Credentials':
      return ['GLUE'];
    case 'AWS S3 Credential':
    case 'AWS IAM User Credentials':
      return ['REST'];
    default:
      return [];
  }
};

export const filterLookupValues = (selectedCredential, lookupValues) => {
  const excludeValues = getExcludeLookupValues(selectedCredential);

  if (excludeValues.length > 0) {
    return lookupValues.filter((val) => !excludeValues.includes(val.id));
  }
  return lookupValues;
};
